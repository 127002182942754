<!--
 * @Date: 2021-03-21 16:42:15
 * @LastEditors: frank
 * @LastEditTime: 2021-05-14 13:30:21
 * @FilePath: /shop_frontend/src/views/memberList/memberDetail.vue
 * @Description: Description
-->
<template>
  <section class="memberDetail page">
    <a-card title="基本信息" style="margin-bottom: 20px">
      <img
        :src="info.avatar_url"
        alt=""
        style="
          display: inline-block;
          width: 80px;
          height: 80px;
          border-radius: 50%;
        "
      />
      <a-row>
        <a-col span="8">昵称：{{ info.name }}</a-col>
        <a-col span="8">邀请人：{{ info.sharer_name }}</a-col>
        <a-col span="8">成为客户：{{ info.create_time }}</a-col>
        <a-col span="8">最近浏览：{{ info.last_login }}</a-col>
        <a-col span="8">手机号：{{ info.phone }}</a-col>
        <a-col span="8">OpenId：{{ info.openid }}</a-col>
        <a-col span="8">累计获得积分：{{ info.accumulated_points }}</a-col>
        <a-col span="8">累计消费金额：{{ info.accumulated_amount }}</a-col>
        <a-col span="8"
          >地址：{{ info.province_name + info.city_name || "" }}</a-col
        >
        <a-col span="8">
          <span
            @click="openOrderList(info)"
            style="cursor: pointer; color: #1890ff"
            >订单列表</span
          >
          <!-- <a-button type="link" @click="openOrderList(info)">订单列表</a-button> -->
        </a-col>
      </a-row>
    </a-card>
    <a-card title="资产信息" style="margin-bottom: 20px">
      <a-row>
        <!-- <a-col span="8">
          <a-card style="width: 300px">
            <p>会员卡</p>
            <p>{{ info.card_name }}</p>
          </a-card>
        </a-col> -->
        <a-col span="8">
          <a-card style="width: 300px">
            <div class="flex_center">
              <icon-font
                type="icon-B-13"
                style="font-size: 50px; margin-right: 20px"
              />
              <div>
                <p>
                  储值金额
                  <!-- <a-popconfirm
                    ok-text="确认"
                    cancel-text="取消"
                    @confirm="confirm"
                    @cancel="cancel"
                    @visibleChange="visibleChange"
                  >
                    <div slot="icon"></div>
                    <div slot="title">
                      <a-input v-model="amount"></a-input>
                    </div>
                    </a-popconfirm> -->
                  <a-button type="link" @click="setAmount">设置</a-button>
                </p>
                <div @click="openBalance" style="color: rgb(24, 144, 255); cursor: pointer;">{{ info.balance || 0 }}</div>
              </div>
            </div>
          </a-card>
        </a-col>
        <a-col span="8">
          <a-card style="width: 300px">
            <div class="flex_center">
              <icon-font
                type="icon-B-02"
                style="font-size: 50px; margin-right: 20px"
              />
              <div>
                <p>
                  可用积分
                  <a-button type="link" @click="addPoints(info)">设置</a-button>
                </p>
                <div @click="openPoints" style="color: rgb(24, 144, 255); cursor: pointer;">{{ info.points || 0 }}</div>
              </div>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </a-card>
    <a-card title="备注信息" style="margin-bottom: 20px">
      <p>{{ info.remark || "" }}</p>
    </a-card>
    <!-- <a-card title="标签信息" style="margin-bottom: 20px">
      <p>{{ info.label_name || "" }}</p>
    </a-card> -->
    <a-modal
      title="修改金额"
      :visible="visible"
      @ok="handleOk"
      @cancel="visible = false"
      width="50%"
    >
      <div>
        <a-row style="margin-bottom: 20px" type="flex" align="middle">
          <a-col span="4">当前金额</a-col>
          <a-col span="19" offset="1">{{ currentRecord.balance || 0 }}</a-col>
        </a-row>
        <a-row style="margin-bottom: 20px" type="flex" align="middle">
          <a-col span="4">增加金额</a-col>
          <a-col span="19" offset="1">
            <a-input class="w220" v-model="addForm.num"></a-input>
            <span>(*当余额为正时，增加余额；余额为负时，减少余额*)</span>
          </a-col>
        </a-row>
        <a-row type="flex" align="middle">
          <a-col span="4">增加备注</a-col>
          <a-col span="19" offset="1">
            <a-input v-model="addForm.remark"></a-input>
          </a-col>
        </a-row>
      </div>
    </a-modal>
    <add-points
      :visible="pointsVisible"
      :currentRecord="currentRecord"
    ></add-points>
  </section>
</template>

<script>
export default {
  name: "memberDetail",
  data() {
    return {
      info: JSON.parse(this.$route.query.info),
      visible: false,
      pointsVisible: false,
      currentRecord: {},
      addForm: {
        num: "",
        remark: "",
      },
    };
  },
  components: {
    addPoints: () => import("./addPoints"),
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getDetail(this.info.id);
  },
  methods: {
     openPoints () {
      this.$router.push({
        name: 'memberPointsDetail',
        query: {
          info: JSON.stringify(this.info)
        }
      })
    },
     openBalance () {
      this.$router.push({
        name: 'memberBalanceDetail',
        query: {
          info: JSON.stringify(this.info)
        }
      })
    },
    addPoints(obj) {
      this.currentRecord = obj;
      this.pointsVisible = true;
    },
    setAmount() {
      this.visible = true;
      this.currentRecord = this.info;
    },
    handleOk() {
      this.$axios
        .post(`/members/${this.currentRecord.id}/add_balance/`, {
          amount: this.addForm.num,
          remark: this.addForm.remark,
        })
        .then(() => {
          this.$message.success("操作成功");
          this.visible = false;
          this.getDetail(this.info.id);
        });
    },
    openOrderList(obj) {
      this.$router.push({
        name: "orderList",
        query: {
          info: JSON.stringify(obj),
        },
      });
    },

    getDetail(id) {
      this.$axios.get(`/members/${id || this.info.id}/`).then((res) => {
        this.info = res;
      });
    },
  },
};
</script>

<style scoped lang="less">
.flex_center {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
</style>
